<template>
    <child-layout :title="title">
        <template #actions>
            <gated-button
                :to="{ name: 'roles-create' }"
                :allowed-roles="allowedRoles"
                class="primary"
            >
                Create
            </gated-button>
        </template>

        <ag-grid-server-side
            id="roleList"
            :server-side-datasource="serverSideDatasource"
            :column-defs="columnDefs"
            server-side-store-type="full"
        />
    </child-layout>
</template>

<script>
const ColourComponent = {
    template: '<span :style="{color: params.color}">{{params.value}}</span>',
}

import AgGridServerSide from '@/components/table/AgGridServerSide'
import columnDefs from '@/helpers/ag-grid/columnDef/roleList'
import pagination from '@/helpers/ag-grid/pagination/rest'
import ChildLayout from '@/components/layout/ChildLayout'
import { mapGetters, mapActions } from 'vuex'
import GatedButton from '@/components/security/GatedButton.vue'
import { ROLE_USER_ROLE_CREATE } from '@/helpers/security/roles'

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { AgGridServerSide, ChildLayout, ColourComponent, GatedButton },
    props: {
        title: { type: String, default: 'Roles' },
    },
    data: () => ({ columnDefs, allowedRoles: [ROLE_USER_ROLE_CREATE] }),
    computed: { ...mapGetters({ roles: 'role/getItems' }) },
    methods: {
        ...mapActions({ fetchRoles: 'role/cget' }),
        serverSideDatasource: function () {
            const fetch = async (params) => {
                await this.fetchRoles({ params })

                return this.roles
            }

            return {
                getRows: async function (params) {
                    const queryPayload = pagination(params)
                    const data = await fetch(queryPayload)

                    params.successCallback(data, data.length)
                },
            }
        },
    },
}
</script>