export default [
    {
        headerName: 'Name',
        field: 'name',
        filterParams: { filterOptions: ['contains'] },
    },
    {
        headerName: 'Role',
        field: 'role',
        filterParams: { filterOptions: ['contains'] },
    },
    {
        headerName: 'Status',
        field: 'status',
        filterParams: { filterOptions: ['contains'] },
    },
    {
        headerName: 'Child Roles',
        field: 'childRoleCount',
        cellRendererFramework: 'ChipCellNumber',
        cellRendererParams: { color: 'primary' },
    },
    {
        cellRendererFramework: 'RowActionButtons',
        cellRendererParams: {
            linkButtons: [
                {
                    routerLinkIds: ['id'],
                    routeName: 'role-edit',
                    role: 'ROLE_USER_ROLE_VIEW',
                    btnText: 'Edit',
                    icon: 'fal fa-pencil',
                },
            ],
        },
        width: 100,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
        filter: false,
    },
]
